/**
 * @mixin.
 */

@mixin mini-arrow($color, $direction: down, $hovering: false, $yTranslateOffset: 0) {

    /**
     * &::after.
     */

    &::after {

        /**
         * Content.
         */

        content: '';

        /**
         * Display.
         */

        display: inline-block;

        /**
         * Margin.
         */

        margin-left: 12px;

        /**
         * Sizing.
         */

        width: 15px;
        height: 10px;

        /**
         * Transition.
         */

        transition: transform 0.2s ease;

        /**
         * Transform.
         */

        transform: translateY($yTranslateOffset + 0px);

        /**
         * @include.
         */

        @include background-mini-arrow($color, $direction);

        /**
         * @if.
         */

        @if $direction == right {

            /**
             * Sizing.
             */

            width: 10px;
            height: 15px;

            /**
             * Transform.
             */

            @if $hovering {

                /**
                 * Transform.
                 */

                transform: translateX(3px) translateY($yTranslateOffset + 0px);

            }

            /**
             * Margin.
             */

            margin-left: 5px;            

        }

    }

}