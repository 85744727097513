/**
 * .header-mobile-navigation-wrapper.
 */

.header-mobile-navigation-wrapper {
    
    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * Display.
     */

    display: none;

    /**
     * Position.
     */

    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;

    /**
     * Z-index.
     */

    z-index: 100;

    /**
     * Border.
     */

    border-radius: 10px;

    /**
     * Shadow.
     */

    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.21);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.21);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.21);

    /**
     * @include.
     */

    @include breakpoint($b-df-tablet) {

        /**
         * Display.
         */

        display: block;

    }

    /**
     * Hide scrollbar for Chrome, Safari and Opera.
     */

    &::-webkit-scrollbar {

        /**
         * Display.
         */

        display: none;
        
    }

    /**
     * Scroll.
     */

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /**
     * @include.
     */

    @include breakpoint(max-height 600px) {

        /**
         * Sizing.
         */

        max-height: 96vh !important;

        /**
         * Overflow.
         */

        overflow-y: scroll;
        
    }

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * Transform.
     */

    transform: translateY(20px);

    /**
     * Transition.
     */

    transition: opacity 0.2s ease, transform 0.2s ease;

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer.
         */

        pointer-events: all;

        /**
         * Transform.
         */

        transform: translateY(0);

    }

    /**
     * &__close.
     */

    &__close {

        /**
         * Display.
         */

        display: block;

        /**
         * Position.
         */

        position: absolute;
        top: 20px;
        right: 20px;

        /**
         * Sizing.
         */

        width: 15px;
        height: 15px;

        /**
         * img, svg.
         */

        img, svg {

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 0;

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;

        }

    }

}

/**
 * .header-mobile-navigation.
 */

.header-mobile-navigation {

    /**
     * Background.
     */

    background-color: white;

    /**
     * Padding.
     */

    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;

    /**
     * @include.
     */

    @include breakpoint($bs-small) {
        
        /**
         * Padding.
         */

        padding-top: 10px;
        padding-bottom: 0;
        padding-left: 25px;
        padding-right: 25px;

    }

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * &__block.
     */

    &__block {

        /**
         * Padding.
         */

        padding-top: 15px;
        padding-bottom: 0;

        /**
         * Margin.
         */

        margin-bottom: 0;

        /**
         * Border.
         */

        border-bottom: 1px solid rgba(#bdbec0, 0.2);

        /**
         * &:last-child.
         */

        &:last-child {

            /**
             * Border.
             */

            border-bottom: 0;

            /**
             * Padding.
             */

            padding-bottom: 0;

            /**
             * Margin.
             */

            margin-bottom: 0;

        }

    }

    /**
     * &__block-heading.
     */

    &__block-heading {

        /**
         * Font related.
         */

        font-size: 22px;
        font-weight: 600;
        color: rgba(#bdbec0, 0.5);

    }

    /**
     * &__nav-item-list.
     */

    &__nav-item-list {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-wrap: wrap;

    }

    /**
     * &__nav-item-list--contact.
     */

    &__nav-item-list--contact {

        /**
         * Position.
         */

        position: relative;

        /**
         * Margin.
         */

        margin-top: 5px;

        /**
         * Padding.
         */

        padding-top: 15px;
        padding-bottom: 15px;

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 50%;

            /**
             * Tranform.
             */

            transform: translateX(-50%);

            /**
             * Sizing.
             */

            width: 120%;
            height: 120%;

            /**
             * Background.
             */

            background-color: #e7f6f9;

            /**
             * Z-index.
             */

            z-index: 1;

        }

        /**
         * .header-mobile-navigation__nav-item.
         */

        .header-mobile-navigation__nav-item {

            /**
             * Margin.
             */

            margin-bottom: 0;

        }

    }

    /**
     * &__nav-item.
     */

    &__nav-item {

        /**
         * Z-index.
         */

        z-index: 2;

        /**
         * Flex.
         */

        flex-basis: 33%;

        /**
         * @include.
         */
        
        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Flex.
             */

            flex-basis: 50%;

        }

        /**
         * Margin.
         */

        margin-bottom: 10px;

        /**
         * &--full-width.
         */

        &--full-width {

            /**
             * Flex.
             */

            flex-basis: 100% !important;

        }

    }

    /**
     * &__contact-nav-list.
     */

    &__contact-nav-list {

        /**
         * Padding.
         */

        padding-left: 45px;

    }

    /**
     * &__contact-nav-list-item.
     */

    &__contact-nav-list-item {

        /**
         * Display.
         */

        display: block;
        
        /**
         * Font related.
         */
        
        @include font-size-m3r();
        color: $brand-three;

        /**
         * Margin.
         */
        
        margin-bottom: 2px;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            color: $brand-one;

        }

    }

}

/**
 * .icon-info-block.
 */

.icon-info-block {

    /**
     * &--mobile-nav &__heading.
     */

    &--mobile-nav &__heading {

        /**
         * @include.
         */
        
        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Font related.
             */
    
            font-size: 15px;            

        }

    }    

    /**
     * &--mobile-nav &__icon.
     */

    &--mobile-nav &__icon {

        /**
         * @include.
         */
        
        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Sizing.
             */

            width: $mobile-nav-icon-width;
            height: $mobile-nav-icon-height;

        }

    }

    /**
     * &--mobile-nav &__info.
     */

    &--mobile-nav &__info {

        /**
         * Margin.
         */

        margin-bottom: 5px;

        /**
         * @include.
         */
        
        @include breakpoint($b-df-mobile-landscape) {
            
            /**
             * Display.
             */

            display: none;

        }

    }

}
