/**
 * .graphic-bullet-list.
 */

.graphic-bullet-list {

    /**
     * 
     */

    li {

        /**
         * Position.
         */

        position: relative;

        /**
         * Margin.
         */

        margin-bottom: 20px;
        margin-left: 0;

        /**
         * Padding.
         */

        padding-left: 40px;

        /**
         * 
         */

        list-style: none;

        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            position: absolute;
            top: 50%;
            left: 0;

            /**
             * Transform.
             */

            transform: translateY(-50%);

            /**
             * Sizing.
             */

            width: 30px;
            height: 30px;

            /**
             * Background.
             */

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.11 23.88'%3E%3Cg id='Layer_9' data-name='Layer 9'%3E%3Cg%3E%3Cpath d='M12,23.88a12,12,0,0,1-3.54-.54A11.94,11.94,0,1,1,21.26,4.48l-.78.62A10.94,10.94,0,1,0,22.4,8.74l1-.29A12,12,0,0,1,12,23.88Z' style='fill: %23ffffff'/%3E%3Cpolygon points='12.91 16.05 7.68 10.82 8.38 10.11 12.91 14.64 27.41 0.14 28.11 0.85 12.91 16.05' style='fill: %23e95197'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;

        }

    }

}