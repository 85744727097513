/**
 * Site-specific breakpoints.
 */

$breakpoint-header-nav-hidden:  $b-df-tablet;
$breakpoint-header-nav-visible: $b-desktop;
$breakpoint-ham-hidden:         $b-desktop;
$fluid:                         max-width 575px;

/**
 * body.
 */

 body {

    /**
     * .site-wrapper.
     */

    .site-wrapper {

        /**
         * Overflow.
         */

        overflow: hidden;

    }

}

/**
 * html.
 */

html {

    /**
     * Scroll.
     */

    scroll-behavior: smooth;

}
