/**
 * .lottie-wrapper.
 */

.lottie-wrapper {
    /**
     * Margin.
     */

    margin-bottom: 20px;

    /**
     * Z-index.
     */

    z-index: 50;

    /**
     * lottie-player.
     */

    lottie-player {
        /**
         * Display.
         */

        display: inline-block;

        /**
         * 
         */

        position: relative;

        /**
         * &.hidden.
         */

        &.hidden {
            /**
             * Opacity.
             */

            opacity: 0;
        }

        /**
         * Transition.
         */

        transition: opacity 0.3s ease;
    }

    /**
     * &--center.
     */

    &--center {
        /**
         * Font related.
         */

        text-align: center;
    }

    /**
     * &--width-90pc lottie-player.
     */

    &--width-90pc lottie-player {
        /**
         * Sizing.
         */

        width: 90%;
    }

    /**
     * &--width-80pc lottie-player.
     */

    &--width-80pc lottie-player {
        /**
         * Sizing.
         */

        width: 80%;
    }

    /**
     * &--width-75pc.
     */

    &--width-75pc lottie-player {
        /**
         * Sizing.
         */

        width: 75%;
    }

    /**
     * &--width-70pc.
     */

    &--width-70pc lottie-player {
        /**
         * Sizing.
         */

        width: 70%;
    }

    /**
     * &--width-65pc.
     */

    &--width-65pc lottie-player {
        /**
         * Sizing.
         */

        width: 65%;
    }

    /**
     * &--small.
     */

    &--small {
        /**
         * lottie-player.
         */

        lottie-player {
            /**
             * Sizing.
             */

            height: 150px !important;
        }
    }

    /**
     * &--homepage.
     */

    &--homepage {
        /**
         * Margin.
         */

        margin-bottom: 0;

        /**
         * Position.
         */

        position: absolute;
        top: 50%;
        left: 55%;

        /**
         * Transform.
         */

        transform: translateY(-50%);

        /**
         * Z-index.
         */

        z-index: 0;

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {
            /**
             * Display.
             */

            display: none;
        }

        /**
         * lottie-player.
         */

        lottie-player {
            /**
             * Sizing.
             */

            width: 55vw;
        }
    }

    /**
     * &--hero.
     */

    &--hero {
        /**
         * Border.
         */

        border: none;

        /**
         * Position.
         */

        position: absolute;
        right: 0;
        top: 0;

        /**
         * Sizing.
         */

        width: 150vw;
        max-width: 1500px;
        /**
         * Transform.
         */

        transform: translateY(-40%) translateX(10%);

        @include breakpoint($b-df-tablet) {
            display: none;
        }
    }

    &--inline {
        display: none;
        padding: 0 10%;
        position: relative;
        height: 7vw;

        lottie-player {
            z-index: 50;
            position: absolute;
            top: 0;
            left: 0;
        }

        @include breakpoint($b-df-tablet) {
            display: block;
        }
    }

    /**
     * &--design.
     */

    &--design {
        /**
         * Sizing.
         */

        width: 65vw;
        transform: translateY(-8%) translateX(35%);

        &--inline {
            width: 80vw;

            lottie-player {
                top: -232%;
                left: 40%;
            }
        }
    }

    /**
     * &--deploy.
     */

    &--deploy {
        /**
         * Sizing.
         */

        width: 90vw;
        transform: translateY(-46%) translateX(30%);

        &--inline {
            width: 120vw;

            lottie-player {
                top: -850%;
                left: 26%;
            }
        }
    }

    /**
     * &--ingest.
     */

    &--ingest {
        /**
         * Sizing.
         */

        width: 120vw;

        /**
         * Transform.
         */

        transform: translateY(-15%) translateX(42%);

        &--inline {
            width: 140vw;

            lottie-player {
                top: -392%;
                left: 14%;
            }
        }
    }

    /**
     * &--engine.
     */

    &--engine {
        /**
         * Sizing.
         */

        width: 110vw;
        transform: translateY(-18%) translateX(17%);

        &--inline {
            width: 130vw;

            lottie-player {
                top: -382%;
                left: 10%;
            }
        }
    }

    /**
     * &--monitor.
     */

    &--monitor {
        /**
         * Sizing.
         */

        width: 100vw;
        transform: translateY(-10%) translateX(16%);

        &--inline {
            width: 110vw;

            lottie-player {
                top: -192%;
                left: 6%;
            }
        }
    }

    /**
     * &--platform.
     */

    &--platform {
        /**
         * Sizing.
         */

        width: 100vw;
        max-width: 1400px;
        transform: translateY(10%) translateX(10%);

        &--inline {
            width: 90vw;

            lottie-player {
                top: -242%;
                left: 14%;
            }
        }
    }

    /**
     * &--center.
     */

    &--center {
        /**
         * Background.
         */

        text-align: center;
    }
}

/**
 * .platform-hero.
 */

.platform-hero {
    /**
     * Position.
     */

    position: relative;

    /**
     * Padding.
     */

    padding-bottom: 22vw;

    /**
     * @include.
     */

    @include breakpoint($b-tablet-only) {
        /**
         * Padding.
         */

        padding-bottom: 10vw !important;
    }
}

/**
 * .z-index-above-graphic.
 */

.z-index-above-graphic {
    /**
     * Z-index.
     */

    z-index: 51;
}

/**
 * .lottie-header.
 */

.lottie-header {
    /**
     * Position.
     */

    position: relative;
}
