/**
 * 
 */

@import '_link-with-graphic.scss';

/**
 * Local variables.
 */

$icon-width: 40px;
$icon-height: 40px;
$large-icon-width: 60px;
$large-icon-height: 60px;
$menu-icon-width: 35px;
$menu-icon-height: 35px;
$mobile-nav-icon-width: 32px;
$mobile-nav-icon-height: 32px;

/**
 * .icon-info-block.
 */

.icon-info-block {

    /**
     * Display.
     */

    display: flex;

    /**
     * Flex.
     */

    flex-direction: row;

    /**
     * Sizing.
     */

    height: 100%;

    /**
     * &__col-icon.
     */

    &__col-icon {

        /**
         * Padding.
         */

        padding-right: 10px;

    }

    /**
     * &--large &__col-icon.
     */

    &--large &__col-icon {

        /**
         * Padding.
         */

        padding-right: 20px;

    }

    /**
     * &__icon.
     */

    &__icon {

        /**
         * Sizing.
         */

        width: $icon-width;
        height: $icon-height;

    }

    /**
     * &--no-arrow.
     */

    &--no-arrow {

        /**
         * .icon-info-block__heading.
         */

        .icon-info-block__heading {
            
            /**
             * &::after.
             */

            &::after {
    
                /**
                 * Content.
                 */

                content: none !important;
    
            }

        }

    }

    /**
     * &--no-prefix.
     */

    &--no-prefix {

        /**
         * .icon-info-block__heading.
         */

        .icon-info-block__heading {
            
            /**
             * &::before.
             */

            &::before {
    
                /**
                 * Content.
                 */

                content: none !important;
    
            }

        }

    }

    /**
     * &--vertical-align-heading.
     */

    &--vertical-align-heading {

        /**
         * .icon-info-block__col-info.
         */

        .icon-info-block__col-info {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            align-items: center;

        }

    }

    /**
     * &--menu, &--large .icon-info-block__heading-wrapper.
     */

    &--menu, &--large .icon-info-block__heading-wrapper {

        /**
         * Display.
         */

        display: inline-flex;

        /**
         * Sizing.
         */

        width: 100%;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * .icon-info-block__heading.
             */

            .icon-info-block__heading {

                /**
                 * Font related.
                 */

                color: $brand-one;

            }

            /**
             * Font related.
             */

            text-decoration: none;

            /**
             * .icon-info-block__heading.
             */

            .icon-info-block__heading {

                /**
                 * @include.
                 */

                @include mini-arrow($brand-one, right, true, 1);

            }
            
        }

        /**
         * .icon-info-block__heading.
         */

        .icon-info-block__heading {

            /**
             * @include.
             */

            @include mini-arrow($brand-one, right, false, 1);

        }

    }

    /**
     * &--large .icon-info-block__heading-wrapper.
     */

    &--large .icon-info-block__heading-wrapper {

        /**
         * .icon-info-block__heading.
         */

        .icon-info-block__heading {

            /**
             * @include.
             */

            @include mini-arrow($brand-one, right, false, -1);

        }

        /**
         * &:hover.
         */

        &:hover {

            /**
             * .icon-info-block__heading.
             */

            .icon-info-block__heading {

                /**
                 * @include.
                 */

                @include mini-arrow($brand-one, right, true, -1);

            }
            
        }

    }

    /**
     * &--menu &__icon.
     */

    &--menu &__icon {

        /**
         * Sizing.
         */

        width: $menu-icon-width;
        height: $menu-icon-height;

    }

    /**
     * &--large &__icon.
     */

    &--large &__icon {

        /**
         * Sizing.
         */

        width: $large-icon-width;
        height: $large-icon-height;

    }

    /**
     * &__heading-wrapper.
     */

    &__heading-wrapper {

        /**
         * Display.
         */

        display: flex;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * .icon-info-block__heading.
             */

            .icon-info-block__heading {

                /**
                 * Font related.
                 */

                color: $brand-one;

            }

            /**
             * @include.
             */

            @include link-with-graphic--hover();

        }

    }

    /**
     * &__heading.
     */

    &__heading {

        /**
         * Margin.
         */

        margin-bottom: 15px;

        /**
         * Font related.
         */

        @include font-size-m6();
        font-weight: 600;
        text-transform: none;

        /**
         * &::before.
         */

        &::before {

            /**
             * Content.
             */

            content: 'Rosetta';

            /**
             * Display.
             */

            display: block;

            /**
             * Font related.
             */

            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
            text-transform: none;

        }

    }

    /**
     * &--menu &__heading.
     */

    &--menu &__heading {

        /**
         * Margin.
         */

        margin-bottom: 1px;

        /**
         * Font related.
         */

        font-size: 20px;

        /**
         * &::before.
         */

        &::before {

            /**
             * Margin.
             */

            margin-bottom: 0px;

        }

    }

    /**
     * &--menu &__info.
     */

    &--menu &__info {

        /**
         * Font related.
         */

        font-size: 12px;

    }

    /**
     * &--large &__heading.
     */

    &--large &__heading {

        /**
         * Position.
         */

        position: relative;

        /**
         * Font related.
         */

        @include font-size-l3r();

        /**
         * &::before.
         */

        &::before {

            /**
             * Margin.
             */

            margin-bottom: 4px;
            
            /**
             * Font related.
             */

            @include font-size-m2();

        }

    }

    /**
     * &__info.
     */

    &__info {

        /**
         * Margin.
         */

        margin-bottom: 15px;

        /**
         * Font related.
         */

        @include font-size-m2();
        font-weight: normal;
        text-transform: none;

    }

    /**
     * &--large &__info.
     */

    &--large &__info {

        /**
         * Font related.
         */

        @include font-size-m5r();

    }

}
