/**
 * .graphic-link-block.
 */

.graphic-link-block {

    /**
     * Display.
     */

    display: block;

    /**
     * &--with-arrow.
     */

    &--with-arrow {

        /**
         * @include.
         */

        @include mini-arrow($brand-one, right, false, 1);

    }

    /**
     * &:hover.
     */

    &:hover {

        /**
         * .graphic-link-block--heading.
         */

        .graphic-link-block--heading {

            /**
             * 
             */

            color: $brand-one !important;

        }

        /**
         * .graphic-link-block--with-arrow.
         */

        .graphic-link-block--with-arrow {

            /**
             * @include.
             */

            @include mini-arrow($brand-one, right, true, 1);

        }

    }

}