/**
 * .product-header-curve.
 */

.product-header-curve {
    /**
     * Position.
     */

    position: absolute;
    top: 0;
    right: 0;

    /**
     * img.
     */

    img {
        /**
         * Sizing.
         */

        width: 60vw;

        /**
         * @include.
         */

        @include breakpoint($b-mobile-only) {
            /**
             * Sizing.
             */

            width: 100vw;
        }
    }
}
