/**
 * @mixin.
 */

@mixin mini() {

    /**
     * Padding.
     */

    padding-top: 15px;
    padding-bottom: 28px;

}

/**
 * @mixin.
 */

@mixin reduced-features() {

    /**
     * Padding.
     */

    padding-top: 20px !important;
    padding-bottom: 20px !important;

}

/**
 * .page-header.
 */

.page-header {

    /**
     * Position.
     */

    position: fixed;
    top: 0;

    /**
     * Z-index.
     */

    z-index: 100;

    /**
     * Sizing.
     */

    width: 100%;

    /**
     * Background.
     */

    background-color: rgba(white, 0.95%);

    /**
     * Padding.
     */

    padding-top: 25px;
    padding-bottom: 30px;

    /**
     * Transition.
     */

    transition: padding 0.2s ease;   

    /**
     * &.mini.
     */

    &.mini {

        /**
         * @include.
         */

        @include mini();

    }

    /**
     * breakpoint.
     */

    @include breakpoint($breakpoint-header-nav-hidden) {

        /**
         * @include.
         */

        @include reduced-features();

    }

}

/**
 * .page-header__col-logo.
 */

.page-header__col-logo {

    /**
     * Display.
     */

    display: flex;

    /**
     * a.
     */

    a {
        
        /**
         * Display.
         */

        display: flex;

        /**
         * Sizing.
         */

        width: 100%;
        height: 100%;

        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            max-width: 100%;
            height: 28px;

        }
    }

    /**
     * @include.
     */

    @include breakpoint($fluid) {

        /**
         * Sizing.
         */

        width: 40%;

    }

}

/**
 * .page-header__col-account.
 */

.page-header__col-account {

    /**
     * Display.
     */

    display: none;

    /**
     * Flex.
     */

    align-items: flex-end;
    justify-content: flex-end;

    /**
     * @include.
     */

    @include breakpoint($breakpoint-header-nav-visible) {

        /**
         * Display.
         */

        display: flex;

    }

}

/**
 * .page-header__sign-in.
 */

.page-header__sign-in {           

    /**
     * Display.
     */

    display: inline-flex;

    /**
     * Flex.
     */

    align-items: center;

    /**
     * @include.
     */

    @include letter-space();

    /**
     * Font related.
     */

    color: $brand-aux-four;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;

    /**
     * Transition.
     */

    transition: color 0.12s ease;

    /**
     * @include.
     */

    @include mini-arrow($brand-aux-four, right);

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        color: $brand-three;
        text-decoration: none;

        /**
         * @include.
         */

        @include mini-arrow($brand-three, right, true);

    }

}

/**
 * .page-header__col-nav.
 */

.page-header__col-nav {

    /**
     * Display.
     */

    display: none;

    /**
     * Flex.
     */

    align-items: flex-end;
 
    /**
     * @include.
     */

    @include breakpoint($breakpoint-header-nav-visible) {

        /**
         * Display.
         */

        display: flex;

    }
    
}

/**
 * .page-header__nav-list.
 */

.page-header__nav-list {

    /**
     * breakpoint.
     */

    @include breakpoint($b-large-desktop-only) {

        /**
         * Padding.
         */

        padding-left: 40px;

    }

    /**
     * Display.
     */

    display: flex;

}

/**
 * .page-header__nav-list-item.
 */

.page-header__nav-list-item {

    /**
     * Position.
     */

    position: relative;

    /**
     * Display.
     */

    display: inline-flex;

    /**
     * Flex.
     */

    align-items: center;

    /**
     * Padding.
     */

    padding-right: 20px;

    /**
     * Font related.
     */

    font-weight: 600;
    color: $brand-aux-one;
    text-transform: uppercase;
    font-size: 15px;

    /**
     * Transition.
     */

    transition: color 0.12s ease;

    /**
     * Cursor.
     */

    cursor: pointer;

    /**
     * a.
     */

    a {

        /**
         * Font related.
         */

        color: $brand-aux-one;

        /**
         * &:hover.
         */

        &:hover {

            /**
             * Font related.
             */

            color: $brand-three;

        }

    }

    /**
     * &.active.
     */

    &.active {

        /**
         * Font related.
         */

        color: $brand-three;

    }

    /**
     * &--with-arrow.
     */

    &--with-arrow {

        /**
         * @include.
         */

        @include mini-arrow($brand-three);

        /**
         * &:hover.
         */

        &:hover {
         
            /**
             * @include.
             */

            @include mini-arrow($brand-three);
            
        }

        /**
         * &::before.
         */

        &::before {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */                

            position: absolute;
            top: -20px;
            left: 0;

            /**
             * Z-index.
             */

            z-index: 1;

            /**
             * Sizing.
             */

            width: 100%;
            height: 40px;

        }

    }

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        color: $brand-three;

        /**
         * .page-header__subnav-wrapper.
         */

        .page-header__subnav-wrapper {

            /**
             * Display.
             */

            display: block;

        }

    }

}

/**
 * .page-header__col-menu-ham.
 */

.page-header__col-menu-ham {

    /**
     * Display.
     */

    display: flex;

    /**
     * Flex.
     */

    justify-content: flex-end;

    /**
     * Sizing.
     */

    width: 60%;

    /**
     * a.
     */

    a {

        /**
         * &:hover.
         */

        &:hover {

            /**
             * svg.
             */

            svg {

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-one;

                }
         
            }

        }
        
    }

    /**
     * svg.
     */

    svg {

        /**
         * Sizing.
         */

        width: 32px;
        height: 32px;

    }

    /**
     * @include.
     */

    @include breakpoint($breakpoint-ham-hidden) {

        /**
         * Display.
         */

        display: none;

    }

}