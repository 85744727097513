/**
 * .two-col-panel.
 */

.two-col-panel {

    /**
     * Display.
     */

    display: flex;

    /**
     * Position.
     */

    position: relative;

    /**
     * &--reverse.
     */

    &--reverse {

        /**
         * Flex.
         */

        flex-direction: row-reverse;

    }

    /**
     * &__col-graphic.
     */

    &__col-graphic {

        /**
         * Font related.
         */

        text-align: center;

    }

    /**
     * &__heading.
     */

    &__heading {

        /**
         * Font related.
         */

        @include font-size-l5r();

        /**
         * Margin.
         */
    
        margin-bottom: 20px;

        /**
         * Font related.
         */

        color: $brand-three;

    }
    
    /**
     * &__graphic-container.
     */

    &__graphic-container {

        /**
         * Position.
         */

        position: relative;

        /**
         * img.
         */

        img {           

            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

            /**
             * Sizing.
             */

            width: 90%;

        }

        /**
         * Sizing.
         */

        width: 496px;
        height: 456px;

        /**
         * Position.
         */

        position: absolute;
        top: 50%;
        left: 50%;

        /**
         * Margin.
         */

        margin-bottom: 0;

        /**
         * Transform.
         */

        transform: translateX(-0%) translateY(-50%);

        /**
         * &.mirror-anim.
         */

        &.mirror-anim {

            /**
             * Transform.
             */

            transform: translateX(-100%) translateY(-50%);

        }

        /**
         * &.shadow.
         */

        &.with-shadow {

            /**
             * img.
             */

            img {
                
                /**
                 * Shadow. 
                 */

                -webkit-box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.64); 
                box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.64);

            }

        }

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Border.
         */

        // border: 4px solid rgba(#2b2b2b, 0.25);
        // border-radius: 20px;

        /**
         * Transition.
         */

        transition: opacity 1s ease, transform 1s ease;

        /**
         * &.entered-view.
         */

        &.entered-view {

            /**
             * Opacity.
             */

            opacity: 1;

            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

        }

        /**
         * &.small.
         */

        &.small {

            /**
             * Sizing.
             */

            width: 270px;
            height: 270px;

            /**
             * @include.
             */

            @include breakpoint($b-tablet-only) {

                /**
                 * Sizing.
                 */

                width: 180px;
                height: 180px;

            }

        }

        /**
         * @include.
         */

        @include breakpoint($b-tablet-only) {

            /**
             * Sizing.
             */

            width: 350px;
            height: 322px;

        }

        /**
         * @include.
         */

        @include breakpoint($b-desktop-only) {

            /**
             * Sizing.
             */

            width: 446px;
            height: 411px;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Position.
             */

            position: relative;
            top: auto;
            left: auto;

            /**
             * Transform.
             */

            transform: none !important;

            /**
             * Sizing.
             */

            width: 100% !important;
            height: 400px !important;
            
        }

    }

}

/**
 * img[data-apply-shadow].
 */

img[data-apply-shadow] {

    /**
     * Shadow. 
     */

    -webkit-box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.64); 
    box-shadow: 0px 0px 14px -2px rgba(0,0,0,0.64);

}
