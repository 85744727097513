// Add this extra ID selector to ensure our styles take priority
#rosetta-website {
    div#hs-eu-cookie-confirmation {
        background-color: rgba(255, 255, 255, 0.95);
        border-top: 3px solid $brand-one;

        div#hs-eu-cookie-confirmation-inner {
            background: transparent;

            a {
                color: $brand-one;
            }

            p {
                @include font-size-m2();
                font-family: "Inter", sans-serif;
                font-weight: 400 !important;
                margin: 0;
            }

            a#hs-eu-decline-button,
            a#hs-eu-confirmation-button {
                display: flex;
                align-items: center;
                font-family: "Inter", sans-serif;
                padding: 12px 20px 12px 25px !important;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 600 !important;
                letter-spacing: 2px;
                border-radius: 20px;
            }

            a#hs-eu-confirmation-button {
                border: 0 !important;
                background-color: $brand-two !important;
                color: $brand-three;
                @include mini-arrow($brand-three, right);

                &:hover {
                    background-color: $brand-aux-one !important;
                    color: $brand-aux-two !important;
                    @include mini-arrow($brand-aux-two, right, true);
                }
            }

            a#hs-eu-decline-button {
                border: 0 !important;
                color: $brand-aux-four !important;
                @include mini-arrow($brand-aux-four, right);

                &:hover {
                    color: $brand-three !important;
                    @include mini-arrow($brand-three, right, true);
                }
            }
        }
    }
}
