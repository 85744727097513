/**
 * .two-col-graphic-placeholder.
 */

.two-col-graphic-placeholder {
    
    /**
     * Sizing.
     */

    width: 496px;
    height: 456px;

    /**
     * Position.
     */

    position: absolute;
    top: 50%;
    left: 50%;

    /**
     * Margin.
     */

    margin-bottom: 0;

    /**
     * Transform.
     */

    transform: translateX(-0%) translateY(-50%);

    /**
     * &.mirror-anim.
     */

    &.mirror-anim {

        /**
         * Transform.
         */

        transform: translateX(-100%) translateY(-50%);

    }

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Border.
     */

    border: 4px solid rgba(#2b2b2b, 0.25);
    border-radius: 20px;

    /**
     * Transition.
     */

    transition: opacity 1s ease, transform 1s ease;

    /**
     * &.entered-view.
     */

    &.entered-view {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Transform.
         */

        transform: translateX(-50%) translateY(-50%);

    }

    /**
     * &.small.
     */

    &.small {

        /**
         * Sizing.
         */

        width: 270px;
        height: 270px;

        /**
         * @include.
         */

        @include breakpoint($b-tablet-only) {

            /**
             * Sizing.
             */

            width: 180px;
            height: 180px;

        }

    }

    /**
     * @include.
     */

    @include breakpoint($b-tablet-only) {

        /**
         * Sizing.
         */

        width: 350px;
        height: 322px;

    }

    /**
     * @include.
     */

    @include breakpoint($b-desktop-only) {

        /**
         * Sizing.
         */

        width: 446px;
        height: 411px;

    }

    /**
     * @include.
     */

    @include breakpoint($b-df-mobile-landscape) {

        /**
         * Position.
         */

        position: relative;
        top: auto;
        left: auto;

        /**
         * Transform.
         */

        transform: none !important;

        /**
         * Sizing.
         */

        width: 100% !important;
        height: 400px !important;
        
    }

}

/**
 * .grid-graphic-placeholder.
 */

.grid-graphic-placeholder {

    /**
     * Display.
     */

    display: inline-block;

    /**
     * Border.
     */

    border: 4px solid rgba(#2b2b2b, 0.25);
    border-radius: 20px;

    /**
     * Sizing.
     */

    width: 100px;
    height: 100px;

    /**
     * Margin.
     */

    margin-bottom: 15px;
}