/**
 * platform-diagram-wrapper.
 */

.platform-diagram-wrapper {

    /**
     * Position.
     */

    position: relative;

    /**
     * ul.
     */

    ul {

        /**
         * li.
         */

        li {

            /**
             * Position.
             */

            position: absolute;

            /**
             * a.
             */

            a {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;

                /**
                 * Font related.
                 */

                font-size: 22px;
                font-weight: bold;
                color: $brand-three;

                /**
                 * @include.
                 */

                @include breakpoint($bs-medium-only) {

                    /**
                     * Font related.
                     */

                    font-size: 14px;
                    
                }

                /**
                 * span.
                 */

                span {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    align-items: center;

                    /**
                     * @include.
                     */

                    @include mini-arrow($brand-one, right, false, 0);

                }

                /**
                 * &::before.
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: 'Rosetta';

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Font related.
                     */

                    font-size: 12px;
                    line-height: 16px;
                    font-weight: normal;
                    text-transform: none;

                }

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                    /**
                     * span.
                     */

                    span {

                        /**
                         * @include.
                         */

                        @include mini-arrow($brand-one, right, true, 0);

                    }

                }

            }

        }

    }

    /**
     * &--lanscape.
     */

    &--landscape { 

        /**
         * .design.
         */

        .design {

            /**
             * Position.
             */

            top: 4.5%;
            left: 15%;

        }

        /**
         * .translate.
         */

        .translate {

            /**
             * Position.
             */

            top: 4.5%;
            left: 46.5%;        

        }

        /**
         * .deploy.
         */

        .deploy {

            /**
             * Position.
             */

            top: 4.5%;
            left: 82.5%;        

        }

        /**
         * .ingest.
         */

        .ingest {

            /**
             * Position.
             */

            top: 61.25%;
            left: 14.5%;

        }

        /**
         * .engine.
         */

        .engine {

            /**
             * Position.
             */

            top: 61.25%;
            left: 48%;

        }

        /**
         * .monitor.
         */

        .monitor {

            /**
             * Position.
             */

            top: 61.25%;
            left: 82%;

        }

        /**
         * .animated-icon.
         */
        
        .animated-icon {

            /**
             * Position.
             */

            position: absolute;

            /**
             * Sizing.
             */

            width: 60px;

            /**
             * &.design-icon.
             */

            &.design-icon {

                /**
                 * Position.
                 */

                top: 3%;
                left: 8.5%;
                
                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { top: 4%; width: 45px; }
                @include breakpoint($b-tablet-only) { top: 4%; width: 30px; }

            }

            /**
             * &.translate-icon.
             */

            &.translate-icon {

                /**
                 * Position.
                 */

                top: 3%;
                left: 40%;

                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { top: 4%; width: 45px; }
                @include breakpoint($b-tablet-only) { top: 4%; width: 30px; }
                
            }

            /**
             * &.deploy-icon.
             */

            &.deploy-icon {

                /**
                 * Position.
                 */

                top: 3%;
                left: 76.5%;
                
                /**
                 * Sizing.
                 */

                width: 52px;

                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { top: 4%; width: 45px; }
                @include breakpoint($b-tablet-only) { top: 4%; width: 30px; }

            }

            /**
             * &.ingest-icon.
             */

            &.ingest-icon {

                /**
                 * Position.
                 */

                top: 60%;
                left: 9%;

                /**
                 * Sizing.
                 */

                width: 50px;
            
                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { left: 10%; top: 62%; width: 35px; }
                @include breakpoint($b-tablet-only) { top: 61%; width: 30px; }
                
            }

            /**
             * &.engine-icon.
             */

            &.engine-icon {

                /**
                 * Position.
                 */

                top: 60%;
                left: 42%;

                /**
                 * Sizing.
                 */

                width: 52px;
                
                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { top: 60%; width: 45px; }
                @include breakpoint($b-tablet-only) { top: 61%; width: 30px; }

            }

            /**
             * &.monitor-icon.
             */

            &.monitor-icon {

                /**
                 * Position.
                 */

                top: 60%;
                left: 76.5%;

                /**
                 * Sizing.
                 */

                width: 48px;
                
                /**
                 * @include.
                 */

                @include breakpoint($b-desktop-only) { left: 77%; top: 62%; width: 35px; }
                @include breakpoint($b-tablet-only) { top: 61%; width: 30px; }

            }

        }

    }

    /**
     * &--portrait.
     */

    &--portrait {

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    font-size: 20px;

                    /**
                     * @include.
                     */

                    @include breakpoint($bs-extra-small-only) {

                        /**
                         * Font related.
                         */

                        font-size: 15px;
                        
                    }

                }                

            }
            
        }

        /**
         * .design.
         */

        .design {

            /**
             * Position.
             */

            top: 8.25%;
            left: 19%;

        }

        /**
         * .translate.
         */

        .translate {

            /**
             * Position.
             */

            top: 20.25%;
            left: 19.5%;

        }

        /**
         * .deploy.
         */

        .deploy {

            /**
             * Position.
             */

            top: 32.25%;
            left: 19.5%;

        }

        /**
         * .ingest.
         */

        .ingest {

            /**
             * Position.
             */

            top: 64%;
            left: 16.5%;

        }

        /**
         * .engine.
         */

        .engine {

            /**
             * Position.
             */

            top: 75.85%;
            left: 20.25%;

        }

        /**
         * .monitor.
         */

        .monitor {

            /**
             * Position.
             */

            top: 87.85%;
            left: 18.5%;

        }

        /**
         * .animated-icon.
         */
        
        .animated-icon {

            /**
             * Position.
             */

            position: absolute;

            /**
             * Sizing.
             */

            width: 40px;

            /**
             * &.design-icon.
             */

            &.design-icon {

                /**
                 * Position.
                 */

                top: 8%;
                left: 8%;
                
            }

            /**
             * &.translate-icon.
             */

            &.translate-icon {

                /**
                 * Position.
                 */

                top: 20%;
                left: 8%;
                
            }

            /**
             * &.deploy-icon.
             */

            &.deploy-icon {

                /**
                 * Position.
                 */

                top: 32%;
                left: 8%;
                
            }

            /**
             * &.ingest-icon.
             */

            &.ingest-icon {

                /**
                 * Position.
                 */

                top: 64%;
                left: 7%;

                /**
                 * Sizing.
                 */

                width: 35px;
               
            }

            /**
             * &.engine-icon.
             */

            &.engine-icon {

                /**
                 * Position.
                 */

                top: 75%;
                left: 7%;

                /**
                 * Sizing.
                 */

                width: 50px;

            }

            /**
             * &.monitor-icon.
             */

            &.monitor-icon {

                /**
                 * Position.
                 */

                top: 87.5%;
                left: 7.5%;

                /**
                 * Sizing.
                 */

                width: 40px;
                
            }

        }

    }



}