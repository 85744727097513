/**
 * canas.
 */

canvas {

    /**
     * Position.
     */

    position: absolute;
    top: 0;
    left: 0;

    /**
     * Sizing.
     */

    width: 100%;

}

/**
 * .body-curve.
 */

.body-curve {

    /**
     * Position.
     */

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

}
