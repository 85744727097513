/**
 * .mini-header-trigger.
 */

.mini-header-trigger {

    /**
     * Position.
     */

    position: absolute;
    top: 200px;
    left: 0;
    
    /**
     * Sizing.
     */

    width: 10px;
    height: 10px;

}
