/**
 * @mixin.
 */

@mixin letter-space() {

    /**
     * Font related.
     */

    letter-spacing: 2px;

}